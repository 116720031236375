import React from 'react';
import s from './CartItem.scss';
import {classes} from './CartItem.st.css';
import {CartItemThumbnail} from '../CartItemThumbnail/CartItemThumbnail';
import {Name} from './partials/Name/Name';
import {Discounts} from './partials/Discounts/Discounts';
import {Price} from './partials/Price/Price';
import {Remove} from './partials/Remove/Remove';
import {Quantity} from './partials/Quantity/Quantity';
import {TotalPrice} from './partials/TotalPrice/TotalPrice';
import {OutOfStock} from './partials/OutOfStock/OutOfStock';
import {PaymentTypeLabel} from './partials/PaymentTypeLabel/PaymentTypeLabel';
import {shouldPresentDetails} from '../../../../domain/utils/itemUtils';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';
import {PaymentOptionType} from '@wix/wixstores-graphql-schema-node';
import {ViolationNotification} from '../../Violations/ViolationNotification/ViolationNotification';
import {Spinner, SpinnerTypes} from 'wix-ui-tpa';
import {Divider} from 'wix-ui-tpa/cssVars';
import classNames from 'classnames';
import {CartItemOptions} from './partials/CartItemOptions/CartItemOptions';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {ViolationModel, ViolationSeverity} from '../../../../domain/models/Violation.model';
// eslint-disable-next-line import/no-deprecated
import {guidToInt} from '@wix/wixstores-client-storefront-sdk';

export enum CartItemDataHook {
  'Root' = 'CartItemDataHook.root',
  'ComparePrice' = 'CartItemDataHook.comparePrice',
  'Name' = 'CartItemDataHook.name',
  'Options' = 'CartItemDataHook.options',
  'FreeText' = 'CartItemDataHook.freeText',
  'Price' = 'CartItemDataHook.price',
  'PaymentTypeLabel' = 'CartItemDataHook.paymentTypeLabel',
  'Quantity' = 'CartItemDataHook.quantity',
  'QuantityErrorTooltip' = 'CartItemDataHook.quantityErrorTooltip',
  'OutOfStockRoot' = 'CartItemDataHook.outOfStockRoot',
  'OutOfStockError' = 'CartItemDataHook.outOfStockError',
  'Remove' = 'CartItemDataHook.remove',
  'TotalPrice' = 'CartItemDataHook.totalPrice',
  'Discounts' = 'CartItemDataHook.Discounts',
  'DiscountName' = 'CartItemDataHook.DiscountName',
  'Divider' = 'CartItemDataHook.divider',
  'loadingSpinner' = 'CartItemDataHook.loadingSpinner',
}

interface CartItemProps {
  lineItem: LineItemModel;
  showQuantityBySettings: boolean;
  showThumbnailBySettings: boolean;
  shouldShowItemInfoBySettings: boolean;
  showDivider: boolean;
  onRemoveItem?: () => void;
  quantityClassName?: string;
  thumbnailClassName?: string;
  infoClassName?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CartItem: React.FC<CartItemProps> = (props: CartItemProps) => {
  const {
    lineItem,
    showQuantityBySettings,
    showThumbnailBySettings,
    shouldShowItemInfoBySettings,
    showDivider,
    onRemoveItem,
    quantityClassName,
    thumbnailClassName,
    infoClassName,
  } = props;
  const {
    cartStore: {shouldDisplayViolations, loadingItems, estimatedTotals, isSummaryUpdating},
  } = useControllerProps();

  const {t} = useTranslation();

  const shouldHideQuantity = lineItem.renderingConfig?.hideQuantity || !showQuantityBySettings;
  const shouldHidePrice = lineItem.renderingConfig?.hidePrice;
  const shouldShowPaymentTypeLabel =
    lineItem.paymentOption && lineItem.paymentOption !== PaymentOptionType.FULL_PAYMENT_ONLINE;
  const isItemOutOfStock = lineItem.quantityAvailable === 0;

  const getLineItemViolationIfItExists = (): ViolationModel | undefined => {
    if (shouldDisplayViolations) {
      return (
        estimatedTotals?.violations.find(
          (violation) =>
            // eslint-disable-next-line import/no-deprecated
            guidToInt(violation.target?.lineItemTarget?.id) === lineItem.id &&
            violation.severity === ViolationSeverity.error
        ) ??
        estimatedTotals?.violations.find(
          (violation) =>
            // eslint-disable-next-line import/no-deprecated
            guidToInt(violation.target?.lineItemTarget?.id) === lineItem.id &&
            violation.severity === ViolationSeverity.warning
        )
      );
    }
  };

  const lineItemViolation = getLineItemViolationIfItExists();
  const shouldShowLineItemViolation = !isItemOutOfStock && Boolean(lineItemViolation);
  const isItemLoading = isSummaryUpdating && loadingItems.includes(lineItem.id);

  return (
    <>
      <div className={s.itemWrapper} data-hook={CartItemDataHook.Root}>
        <div className={classNames(s.item, ...(isItemLoading ? /* istanbul ignore next */ [s.loading] : []))}>
          <div className={s.info}>
            {isItemLoading ? (
              /* istanbul ignore next */ <Spinner
                data-hook={CartItemDataHook.loadingSpinner}
                isCentered
                type={SpinnerTypes.slim}
              />
            ) : undefined}
            {showThumbnailBySettings && <CartItemThumbnail lineItem={lineItem} className={thumbnailClassName} />}
            <div className={s.details}>
              <div className={s.content}>
                <Name lineItem={lineItem} />
                <Discounts lineItem={lineItem}></Discounts>
                {!shouldHidePrice && <Price lineItem={lineItem} />}
                {shouldShowPaymentTypeLabel && <PaymentTypeLabel lineItem={lineItem} />}
                {shouldPresentDetails(lineItem) && shouldShowItemInfoBySettings && (
                  <CartItemOptions
                    itemId={lineItem.id}
                    options={[...lineItem.descriptionLines]}
                    maxOptionValLength={500}
                    collapseSettings={{
                      numberOfOptionsToCollapseAfter: 1,
                      showMoreTitle: t('sideCart.lineItems.moreDetails'),
                      showLessTitle: t('sideCart.lineItems.lessDetails'),
                    }}
                    className={infoClassName}
                  />
                )}
              </div>
              {
                <div className={s.priceAndQuantity}>
                  {!shouldHideQuantity && <Quantity lineItem={lineItem} className={quantityClassName} />}
                  <TotalPrice lineItem={lineItem} />
                </div>
              }
            </div>
          </div>
          <div className={s.colEnd}>
            <Remove lineItem={lineItem} onRemoveItem={onRemoveItem} />
          </div>
        </div>
        {isItemOutOfStock && <OutOfStock />}
        {shouldShowLineItemViolation && (
          <div className={s.violation}>
            <ViolationNotification violation={lineItemViolation} />
          </div>
        )}
      </div>
      {showDivider ? <Divider className={classes.divider} data-hook={CartItemDataHook.Divider} /> : null}
    </>
  );
};
